import axios from "axios";
import { getApiUrl } from "@/utils";

// initial state
const state = {
  pageviews: [],
  visits: [],
  bounce_rate: [],
  avg_duration: [],
  archivedArticles: [],
  article: {},
  analysisCategories: [],
  archivedAnalysisCategories: [],
};

const getters = {};

// actions
const actions = {
  loadAnalysisModule({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/analysis`,
        method: "GET",
      })
        .then((resp) => {
          commit("moduleLoadAnalysisSuccess", resp.data);
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
  loadArticle({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/analysis/${data.articleId}`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadArticleSuccess", resp.data);
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
  saveArticle({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/analysis/${data.articleId}`
      : `${getApiUrl(data.appId)}/module/analysis`;

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: data.fields,
        method: "POST",
      })
        .then((resp) => {
          commit("saveArticleSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
  deleteArticle({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/analysis/${data.articleId}`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteArticleSuccess", { articleId: data.articleId });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },
  loadAnalysisCategories({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/analysis/categories`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadAnalysisCategoriesSuccess", resp.data);
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
  saveAnalysisCategory({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/analysis/categories/${data.categoryId}`
      : `${getApiUrl(data.appId)}/module/analysis/categories`;

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: data.fields,
        method: "POST",
      })
        .then((resp) => {
          commit("saveAnalysisCategorySuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
  deleteAnalysisCategory({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/analysis/categories/${
          data.categoryId
        }`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteAnalysisCategorySuccess", {
            categoryId: data.categoryId,
          });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },
};

// mutations
const mutations = {
  moduleLoadAnalysisSuccess(state, data) {
    state.pageviews = data.pageviews;
    state.visits = data.visits;
    state.bounce_rate = data.bounce_rate;
    state.avg_duration = data.avg_duration;
  },
  loadArticleSuccess(state, data) {
    state.article = data.article;
  },
  saveArticleSuccess(state, data) {
    if (data.isEditing) {
      const index = state.articles.findIndex((a) => a.id === data.article.id);
      state.articles.splice(index, 1, data.article);
    } else {
      state.articles.push(data.article);
    }
  },
  deleteArticleSuccess(state, data) {
    const index = state.articles.findIndex((a) => a.id === data.articleId);
    state.archivedArticles.push(state.articles[index]);
    state.articles.splice(index, 1);
  },
  loadAnalysisCategoriesSuccess(state, data) {
    state.analysisCategories = data.categories;
    state.archivedAnalysisCategories = data.archived;
  },
  saveAnalysisCategorySuccess(state, data) {
    if (data.isEditing) {
      const index = state.analysisCategories.findIndex(
        (a) => a.id === data.category.id
      );
      state.analysisCategories.splice(index, 1, data.category);
    } else {
      state.analysisCategories.push(data.category);
    }
  },
  deleteAnalysisCategorySuccess(state, data) {
    const index = state.analysisCategories.findIndex(
      (c) => c.id === data.categoryId
    );
    state.archivedAnalysisCategories.push(state.analysisCategories[index]);
    state.analysisCategories.splice(index, 1);
  },
};

// modules
const modules = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules,
};
