// import axios from "axios";
import customersStore from "./customers.store";
import membershipsStore from "./memberships.store";

// default state
const getDefaultState = () => {
  return {};
};

// initial state
const state = getDefaultState();

// getters
const getters = {};

// actions
const actions = {};

// mutations
const mutations = {};

// modules
const modules = {
  customersStore,
  membershipsStore,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules,
};
