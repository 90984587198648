import axios from "axios";
import { getApiUrl } from "@/utils";

const state = {
  applications: [],
  groups: [],
  application: {},
};

// getters
const getters = {
  all: (state) => state.applications,
  get: (state) => state.application,
  groups: (state) => state.groups,
};

// actions
const actions = {
  saveVouch({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/sbpm/applications/${
      data.applicationId
    }/checklist/${data.checklistId}/${data.vouchId}`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveVouchSuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  rejectApplication({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/sbpm/applications/${
          data.applicationId
        }/reject`,
        data: {
          comment_text: data.comment_text,
          reason: data.reason,
        },
        method: "POST",
      })
        .then((resp) => {
          commit("rejectApplicationSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  rejectGroup({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/sbpm/applications/groups/${
          data.groupId
        }/reject`,
        data: {
          comment_text: data.comment_text,
          reason: data.reason,
        },
        method: "POST",
      })
        .then((resp) => {
          commit("rejectGroupApplicationSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  changeStatusApplication({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/sbpm/applications/${
          data.applicationId
        }/status`,
        data: {
          status: data.status,
        },
        method: "POST",
      })
        .then((resp) => {
          commit("changeStatusApplicationSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  saveInterented({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/sbpm/applications/${
      data.applicationId
    }/change-interented`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveInterentedSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  saveNotAttendingViewing({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/sbpm/applications/${
      data.applicationId
    }/change-not-attending-viewing`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveAttendingViewingSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  saveAttendingViewing({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/sbpm/applications/${
      data.applicationId
    }/change-attending-viewing`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveAttendingViewingSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  uploadStatus({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/sbpm/applications/${
          data.applicationId
        }/status-upload-comments`,
        data: {
          field: data.field,
        },
        method: "POST",
      })
        .then((resp) => {
          commit("uploadStatusSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  loadApplications({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/sbpm/applications`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadApplicationsSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  loadApplication({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/sbpm/applications/${
          data.applicationId
        }`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadApplicationsuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteApplication({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/sbpm/applications/${
          data.applicationId
        }`,
        method: "DELETE",
      })
        .then((resp) => {
          const group = resp.data.group;

          commit("deleteApplicationSuccess", {
            applicationId: data.applicationId,
            groupId: group,
          });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  saveNote({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/sbpm/applications/${
      data.applicationId
    }/note`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveNoteSuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  downloadApplicationPdf(_, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/sbpm/applications/${
          data.applicationId
        }/pdf`,
        method: "POST",
        responseType: "arraybuffer",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  saveApplication({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/sbpm/applications/${
      data.applicationId
    }`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (
        fields[field] === null ||
        typeof fields[field] === "undefined"
      ) {
        formData.append(field, "");
      } else if (typeof fields[field] === "object") {
        if (fields[field] instanceof File) {
          formData.append(field, fields[field]);
        }
        const data = fields[field];
        for (let i = 0; i < data.length; i++) {
          formData.append(field + "[]", data[i]);
        }
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveApplicationsuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
};

// mutations
const mutations = {
  loadApplicationsSuccess(state, data) {
    state.applications = data.applications;
    state.groups = data.groups;
    state.archived = data.archived;
  },
  loadApplicationsuccess(state, data) {
    state.application = data.application;
  },

  uploadStatusSuccess() {},

  rejectApplicationSuccess(state, data) {
    if (data.isEditing) {
      state.application = data.application;
    } else {
      const groupIndex = state.groups.findIndex(
        (c) => c.id === parseInt(data.application.application_group_id)
      );

      const index = state.groups[groupIndex].applications.findIndex(
        (c) => c.id === data.application.id
      );

      state.groups[groupIndex].applications.splice(index, 1, data.application);
    }
  },

  rejectGroupApplicationSuccess(state, data) {
    const groupIndex = state.groups.findIndex(
      (c) => c.id === parseInt(data.group.id)
    );

    state.groups.splice(groupIndex, 1, data.group);
  },

  changeStatusApplicationSuccess(state, data) {
    state.application = data.application;
  },

  deleteApplicationSuccess(state, data) {
    const groupIndex = state.groups.findIndex(
      (c) => c.id === parseInt(data.groupId)
    );

    const index = state.groups[groupIndex].applications.findIndex(
      (c) => c.id === data.applicationId
    );

    state.groups[groupIndex].applications.splice(index, 1);

    if (state.groups[groupIndex].applications.length == 0) {
      state.groups.splice(groupIndex, 1);
    }
  },
  deleteFileSuccess(state, data) {
    var fileType = data.file_type;

    var index = state.application[fileType].findIndex(
      (c) => c.id === data.fileId
    );
    state.application[fileType].splice(index, 1);
  },

  saveAttendingViewingSuccess(state, data) {
    state.application = data.application;
  },

  saveInterentedSuccess(state, data) {
    state.application = data.application;
  },

  saveVouchSuccess(state, data) {
    if (data.vouch.checklist_items.checklist_id == 1) {
      const index = state.application.vouch.findIndex(
        (c) => c.pivot.id === data.vouch.id
      );

      state.application.vouch[index].pivot = data.vouch;
    } else {
      const index = state.application.guarantor_checklist.findIndex(
        (c) => c.pivot.id === data.vouch.id
      );

      state.application.guarantor_checklist[index].pivot = data.vouch;
    }
  },

  saveNoteSuccess(state, data) {
    state.application.notes.unshift(data.note);
    state.application = data.application;
  },
  saveAgentosSuccess(state, data) {
    state.application = data.application;
  },
  saveGuarantorSuccess(state, data) {
    state.application.audits.push(data.audit);
  },
  saveApplicationsuccess(state, data) {
    state.application = data.application;
  },
  changeStatusSuccess(state, data) {
    state.application = data.application;
  },
  changeStatusUploadSuccess(state, data) {
    state.application = data.application;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
