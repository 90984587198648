import axios from "axios";
import { getApiUrl } from "@/utils";

// initial state
const state = {
  customers: [],
  customer: [],
  sectors: [],
  archived: [],
};

// getters
const getters = { all: (state) => state.customers };

// actions
const actions = {
  loadModule({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("moduleLoadRequest");
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/customers/module`,
        method: "GET",
      })
        .then((resp) => {
          const module = resp.data;
          commit("moduleLoadSuccess", { module });
          resolve(resp);
        })
        .catch((err) => {
          commit("moduleLoadError");
          reject(err);
        });
    });
  },
  loadProfile({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("moduleLoadProfileRequest");
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/customers/${data.customerId}`,
        method: "GET",
      })
        .then((resp) => {
          const customer = resp.data.customer;
          commit("moduleLoadProfileSuccess", { customer });
          resolve(resp);
        })
        .catch((err) => {
          commit("moduleLoadProfileError");
          reject(err);
        });
    });
  },
  loadCustomers({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("loadCustomersRequest");
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/customers`,
        method: "GET",
      })
        .then((resp) => {
          const customers = resp.data.customers;
          commit("loadCustomersSuccess", { customers });
          resolve(resp);
        })
        .catch((err) => {
          commit("loadCustomersError");
          reject(err);
        });
    });
  },
  addCustomer({ commit }, data) {
    commit("addCustomerRequest");
    return new Promise((resolve, reject) => {
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/customers`,
        data: {
          name: data.name,
          website: data.website,
          sector: data.sector,
          vat_number: data.vat_number,
          relationship: data.relationship,
          shortcode: data.shortcode,
          company_email: data.company_email,
          notes: data.notes,
        },
        method: "POST",
      })
        .then((response) => {
          const customer = response.data.customer;
          const sectors = response.data.sectors;
          commit("addCustomerSuccess", { customer, sectors });
          resolve();
        })
        .catch((error) => {
          commit("addCustomerError");
          reject(error);
        });
    });
  },
  editCustomer({ commit }, data) {
    commit("editCustomerRequest");
    return new Promise((resolve, reject) => {
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/customers/${data.customerId}`,
        data: {
          name: data.name,
          website: data.website,
          sector: data.sector,
          vat_number: data.vat_number,
          relationship: data.relationship,
          shortcode: data.shortcode,
          company_email: data.company_email,
          notes: data.notes,
        },
        method: "POST",
      })
        .then((response) => {
          const customer = response.data.customer;
          const sectors = response.data.sectors;
          commit("editCustomerSuccess", { customer, sectors });
          resolve();
        })
        .catch((error) => {
          commit("editCustomerError");
          reject(error);
        });
    });
  },
  updateRelationship({ commit }, data) {
    commit("updateRelationshipRequest");
    return new Promise((resolve, reject) => {
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/customers/relationships`,
        data: {
          id: data.id,
          relationship: data.relationship,
        },
        method: "POST",
      })
        .then((resp) => {
          const customer = resp.data.customer;
          commit("updateRelationshipSuccess", { customer });
          resolve();
        })
        .catch((err) => {
          commit("updateRelationshipError");
          reject(err);
        });
    });
  },

  saveAgreement({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/customers/${
          data.customerId
        }/agreements/${data.agreementId}`
      : `${getApiUrl(data.appId)}/module/customers/${
          data.customerId
        }/agreements`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveAgreementSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  archiveCustomer({ commit }, data) {
    commit("archiveCustomerRequest");
    return new Promise((resolve, reject) => {
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/customers/archive`,
        data: {
          id: data.id,
        },
        method: "POST",
      })
        .then((resp) => {
          const archived = resp.data.archived;
          commit("archiveCustomerSuccess", { archived, id: data.id });
          resolve();
        })
        .catch((err) => {
          commit("archiveCustomerError");
          reject(err);
        });
    });
  },
  reactivateCustomer({ commit }, data) {
    commit("reactivateCustomerRequest");
    return new Promise((resolve, reject) => {
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/customers/reactivate`,
        data: {
          id: data.id,
        },
        method: "POST",
      })
        .then((resp) => {
          const customer = resp.data.customer;
          commit("reactivateCustomerSuccess", { customer, id: data.id });
          resolve();
        })
        .catch((err) => {
          commit("reactivateCustomerError");
          reject(err);
        });
    });
  },
  addContact({ commit }, data) {
    commit("addContactRequest");
    return new Promise((resolve, reject) => {
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/customers/${data.customerId}/contacts`,
        data: {
          name: data.name,
          phone_number: data.phone_number,
          customer_id: data.customerId,
          email: data.email,
          notes: data.notes,
        },
        method: "POST",
      })
        .then((response) => {
          const contact = response.data.contact;
          commit("addContactSuccess", { contact });
          resolve();
        })
        .catch((error) => {
          commit("addContactError");
          reject(error);
        });
    });
  },
  editContact({ commit }, data) {
    commit("editContactRequest");
    return new Promise((resolve, reject) => {
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/customers/${data.customerId}/contacts/${data.contactId}`,
        data: {
          id: data.id,
          customerId: data.customerId,
          name: data.name,
          phone_number: data.phone_number,
          email: data.email,
          notes: data.notes,
        },
        method: "POST",
      })
        .then((response) => {
          const contact = response.data.contact;
          commit("editContactSuccess", { contact });
          resolve();
        })
        .catch((error) => {
          commit("editContactError");
          reject(error);
        });
    });
  },
  addDomain({ commit }, data) {
    commit("addDomainRequest");
    return new Promise((resolve, reject) => {
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/customers/${data.customerId}/domains`,
        data: {
          domain_name: data.domain_name,
          manager_register: data.manager_register,
          type: data.type,
          hosting_package: data.hosting_package,
          server_name: data.server_name,
          customer_id: data.customer_id,
          hosting_by: data.hosting_by,
          customerId: data.customerId,
        },
        method: "POST",
      })
        .then((response) => {
          const domain = response.data.domain;
          commit("addDomainSuccess", { domain });
          resolve();
        })
        .catch((error) => {
          commit("addDomainError");
          reject(error);
        });
    });
  },
  editDomain({ commit }, data) {
    commit("editDomainRequest");
    return new Promise((resolve, reject) => {
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/customers/${data.customerId}/domains/${data.domainId}`,
        data: {
          id: data.id,
          customerId: data.customerId,
          domain_name: data.domain_name,
          manager_register: data.manager_register,
          type: data.type,
          hosting_package: data.hosting_package,
          server_name: data.server_name,
          customer_id: data.customer_id,
          hosting_by: data.hosting_by,
        },
        method: "POST",
      })
        .then((response) => {
          const domain = response.data.domain;
          commit("editDomainSuccess", { domain });
          resolve();
        })
        .catch((error) => {
          commit("editDomainError");
          reject(error);
        });
    });
  },
  archiveDomain({ commit }, data) {
    commit("archiveDomainRequest");
    return new Promise((resolve, reject) => {
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/customers/${data.customerId}/domains/${data.domainId}/archive`,
        data: {
          domainId: data.domainId,
          customerId: data.customerId,
        },
        method: "POST",
      })
        .then((resp) => {
          const domain = resp.data.domain;
          commit("archiveDomainSuccess", { domain, id: data.domainId });
          resolve();
        })
        .catch((err) => {
          commit("archiveDomainError");
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  moduleLoadRequest() {},
  moduleLoadSuccess(state, data) {
    state.customers = data.module.customers;
    state.sectors = data.module.sectors;
    state.archived = data.module.archived;
  },
  moduleLoadError() {},
  moduleLoadProfileRequest() {},
  moduleLoadProfileSuccess(state, data) {
    state.customer = data.customer;
  },
  moduleLoadProfileError() {},
  loadCustomersRequest() {},
  loadCustomersSuccess(state, data) {
    state.customers = data.customers;
  },
  loadCustomersError() {},
  addCustomerRequest() {},
  addCustomerSuccess(state, data) {
    state.customers.push(data.customer);
    state.sectors = data.sectors;
  },
  addCustomerError() {},
  editCustomerRequest() {},
  editCustomerSuccess(state, data) {
    const index = state.customers.findIndex((p) => p.id === data.customer.id);
    state.customers.splice(index, 1, data.customer);
    state.customer = data.customer;
  },

  saveAgreementSuccess(state, data) {
    if (data.isEditing) {
      const index = state.customer.agreements.findIndex(
        (p) => p.id === data.customer.id
      );
      state.customer.agreements.splice(index, 1, data.agreement);
    } else {
      state.customer.agreements.push(data.agreement);
    }
  },

  editCustomerError() {},
  updateRelationshipRequest() {},
  updateRelationshipSuccess(state, data) {
    const index = state.customers.findIndex((c) => c.id === data.customer.id);
    state.customers[index].relationship = data.customer.relationship;
  },
  updateRelationshipError() {},
  archiveCustomerRequest() {},
  archiveCustomerSuccess(state, data) {
    state.archived = data.archived;
    state.customers = state.customers.filter(function (customer) {
      return customer.id !== data.id;
    });
  },
  archiveCustomerError() {},
  reactivateCustomerRequest() {},
  reactivateCustomerSuccess(state, data) {
    state.customers.push(data.customer);
    state.archived = state.archived.filter(function (customer) {
      return customer.id !== data.id;
    });
  },
  reactivateCustomerError() {},
  addContactRequest() {},
  addContactSuccess(state, data) {
    state.customer.contacts.push(data.contact);
  },
  addContactError() {},
  editContactRequest() {},
  editContactSuccess(state, data) {
    const index = state.customer.contacts.findIndex(
      (c) => c.id === data.contact.id
    );
    const contact = data.contact;

    state.customer.contacts[index].name = contact.name;
    state.customer.contacts[index].phone_number = contact.phone_number;
    state.customer.contacts[index].email = contact.email;
    state.customer.contacts[index].notes = contact.notes;
  },
  editContactError() {},
  addDomainRequest() {},
  addDomainSuccess(state, data) {
    state.customer.domains.push(data.domain);
  },
  addDomainError() {},
  editDomainRequest() {},
  editDomainSuccess(state, data) {
    const index = state.customer.domains.findIndex(
      (d) => d.id === data.domain.id
    );
    const domain = data.domain;

    state.customer.domains[index].domain_name = domain.domain_name;
    state.customer.domains[index].manager_register = domain.manager_register;
    state.customer.domains[index].type = domain.type;
    state.customer.domains[index].hosting_package = domain.hosting_package;
    state.customer.domains[index].server_name = domain.server_name;
    state.customer.domains[index].hosting_by = domain.hosting_by;
  },
  editDomainError() {},
  archiveDomainRequest() {},
  archiveDomainSuccess(state, data) {
    state.customer.domains = state.customer.domains.filter(function (domain) {
      return domain.id !== data.id;
    });
  },
  archiveDomainError() {},
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
