import axios from "axios";
import { getApiUrl } from "@/utils";

// initial state
const state = {
  submissions: [],
  submissionsStats: [],
  formNames: [],
  archivedSubmissions: [],
  recipients: [],
  archivedRecipients: [],
  sources: [],
  types: [],
  listSources: [],
  listTypes: [],
};

// getters
const getters = {};

// actions
const actions = {
  loadSubmissions({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/contact-forms`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadSubmissionsSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  loadStats({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/contact-forms/stats`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadStatsSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  loadFiltredStats({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/contact-forms/stats`;
    return new Promise((resolve, reject) => {
      axios({
        url,
        data: data.fields,
        method: "POST",
      })
        .then((resp) => {
          commit("loadStatsSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  loadFiltredSubmissions({ commit }, data) {
    const url = `${getApiUrl(
      data.appId
    )}/module/contact-forms/filter-submissions`;
    return new Promise((resolve, reject) => {
      axios({
        url,
        data: data.fields,
        method: "POST",
      })
        .then((resp) => {
          commit("loadFiltredSubmissionsSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  deleteSubmission({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/contact-forms/${
          data.submissionId
        }`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteSubmissionSuccess", {
            submissionId: data.submissionId,
          });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },

  deleteType({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/contact-forms/types/${
          data.typeId
        }`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteTypeSuccess", {
            typeId: data.typeId,
          });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },

  deleteSource({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/contact-forms/sources/${
          data.sourceId
        }`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteSourceSuccess", {
            sourceId: data.sourceId,
          });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },

  downloadCsv(_, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/contact-forms/csv`,
        data: data.fields,
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  restoreSubmission({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/contact-forms/${
          data.submissionId
        }/restore`,
        method: "POST",
      })
        .then(() => {
          commit("restoreSubmissionSuccess", data);
          resolve();
        })
        .catch(() => reject());
    });
  },

  saveRecipient({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/contact-forms/recipients/${
          data.recipientId
        }`
      : `${getApiUrl(data.appId)}/module/contact-forms/recipients`;

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: data.fields,
        method: "POST",
      })
        .then((resp) => {
          commit("saveRecipientSuccess", resp.data);
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  assignType({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/contact-forms/${
          data.submissionId
        }/add-type`,
        data: {
          ...data,
        },
        method: "POST",
      })
        .then((resp) => {
          commit("saveAssignType", resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  assignSource({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/contact-forms/${
          data.submissionId
        }/add-source`,
        data: {
          ...data,
        },
        method: "POST",
      })
        .then((resp) => {
          commit("saveAssignSource", resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  storeType({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/contact-forms/types/${data.typeId}`
      : `${getApiUrl(data.appId)}/module/contact-forms/types`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveTypeSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  storeValue({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/contact-forms/${
      data.submissionId
    }/add-value`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveValueSuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  storeSource({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/contact-forms/sources/${data.sourceId}`
      : `${getApiUrl(data.appId)}/module/contact-forms/sources`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveSourceSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  deleteRecipient({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/contact-forms/recipients/${
          data.recipientId
        }`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteRecipientSuccess", { recipientId: data.recipientId });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },

  restoreRecipient({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/contact-forms/recipients/${
          data.recipientId
        }/restore`,
        method: "POST",
      })
        .then(() => {
          commit("restoreRecipientSuccess", data);
          resolve();
        })
        .catch(() => reject());
    });
  },

  saveMatching({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/melrose/properties/matching`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveMatchingSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
};

// mutations
const mutations = {
  loadStatsSuccess(state, data) {
    state.submissionsStats = data.submissionsStats;
    state.types = data.types;
    state.sources = data.sources;
  },

  loadSubmissionsSuccess(state, data) {
    state.submissions = data.submissions;
    state.formNames = data.formNames;
    state.archivedSubmissions = data.archivedSubmissions;
    state.recipients = data.recipients;
    state.archivedRecipients = data.archivedRecipients;

    state.listTypes = data.listTypes;
    state.listSources = data.listSources;
  },

  loadFiltredSubmissionsSuccess(state, data) {
    state.submissions = data.submissions;
  },

  deleteSubmissionSuccess(state, data) {
    const index = state.submissions.findIndex(
      (s) => s.id === data.submissionId
    );
    state.archivedSubmissions.push(state.submissions[index]);
    state.submissions.splice(index, 1);
  },

  deleteSourceSuccess(state, data) {
    const index = state.listSources.findIndex((s) => s.id === data.sourceId);

    state.listSources.splice(index, 1);
  },

  deleteTypeSuccess(state, data) {
    const index = state.listTypes.findIndex((r) => r.id === data.typeId);

    state.listTypes.splice(index, 1);
  },

  restoreSubmissionSuccess(state, data) {
    const index = state.archivedSubmissions.findIndex(
      (s) => s.id === data.submissionId
    );
    state.submissions.push(state.archivedSubmissions[index]);
    state.archivedSubmissions.splice(index, 1);
  },

  saveMatchingSuccess(state, data) {
    state.submissions.push(data.submission);
  },

  saveRecipientSuccess(state, data) {
    if (data.isEditing) {
      const index = state.recipients.findIndex(
        (r) => r.id === data.recipient.id
      );
      state.recipients.splice(index, 1, data.recipient);
    } else {
      state.recipients.push(data.recipient);
    }
  },

  saveValueSuccess(state, data) {
    const index = state.submissions.findIndex(
      (s) => s.id === data.submission.id
    );

    state.submissions.splice(index, 1, data.submission);
  },

  saveSourceSuccess(state, data) {
    if (data.isEditing) {
      const index = state.listSources.findIndex((r) => r.id === data.source.id);
      state.listSources.splice(index, 1, data.source);
    } else {
      state.listSources.push(data.source);
    }
  },

  saveTypeSuccess(state, data) {
    if (data.isEditing) {
      const index = state.listTypes.findIndex((r) => r.id === data.type.id);
      state.listTypes.splice(index, 1, data.type);
    } else {
      state.listTypes.push(data.type);
    }
  },

  saveAssignSource(state, data) {
    const index = state.submissions.findIndex(
      (r) => r.id === data.submission.id
    );
    state.submissions.splice(index, 1, data.submission);
  },

  saveAssignType(state, data) {
    const index = state.submissions.findIndex(
      (r) => r.id === data.submission.id
    );
    state.submissions.splice(index, 1, data.submission);
  },

  deleteRecipientSuccess(state, data) {
    const index = state.recipients.findIndex((r) => r.id === data.recipientId);
    state.archivedRecipients.push(state.recipients[index]);
    state.recipients.splice(index, 1);
  },
  restoreRecipientSuccess(state, data) {
    const index = state.archivedRecipients.findIndex(
      (r) => r.id === data.recipientId
    );
    state.recipients.push(state.archivedRecipients[index]);
    state.archivedRecipients.splice(index, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
