import axios from "axios";
import { getApiUrl } from "@/utils";

const state = {
  checklists: [],
  checklist: {},
};

// getters
const getters = {
  all: (state) => state.checklists,
  get: (state) => state.checklist,
};

// actions
const actions = {
  loadChecklists({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/sbpm/checklists`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadChecklistsSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  loadChecklist({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/sbpm/checklists/${
          data.checklistId
        }`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadChecklistSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateChecklistDisplayOrder({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/sbpm/checklists/${
          data.checklistId
        }/display-order`,
        method: "POST",
        data: {
          displayOrder: data.displayOrder,
        },
      })
        .then((resp) => {
          commit("updateChecklistDisplayOrderSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  saveItem({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/sbpm/checklists/${
          data.checklistId
        }/items/${data.itemId}`
      : `${getApiUrl(data.appId)}/module/sbpm/checklists/${
          data.checklistId
        }/items`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveItemSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  saveChecklist({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/sbpm/checklists/${data.checklistId}`
      : `${getApiUrl(data.appId)}/module/sbpm/checklists`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveChecklistSuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
  deleteChecklist({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/sbpm/checklists/${
          data.checklistId
        }`,
        method: "DELETE",
      })
        .then(() => {
          commit("deletePropertySuccess", {
            checklistId: data.checklistId,
          });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteItem({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/sbpm/checklists/${
          data.checklistId
        }/items/${data.itemId}`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteItemSuccess", {
            itemId: data.itemId,
          });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  loadChecklistsSuccess(state, data) {
    state.checklists = data.checklists;
  },

  loadChecklistSuccess(state, data) {
    state.checklist = data.checklist;
  },

  saveChecklistSuccess(state, data) {
    state.checklists.push(data.checklist);
  },

  updateChecklistDisplayOrderSuccess(state, data) {
    state.checklist.items = data.items;
  },

  deletePropertySuccess(state, data) {
    const index = state.properties.findIndex(
      (c) => c.property_id === data.propertyId
    );
    state.properties.splice(index, 1);
  },

  deleteItemSuccess(state, data) {
    const index = state.checklist.items.findIndex((c) => c.id === data.itemId);
    state.checklist.items.splice(index, 1);
  },

  saveItemSuccess(state, data) {
    if (data.isEditing) {
      const index = state.checklist.items.findIndex(
        (p) => p.id === data.item.id
      );
      state.checklist.items.splice(index, 1, data.item);
    } else {
      state.checklist.items.push(data.item);
    }
  },

  savePropertyVisibleSuccess(state, data) {
    const index = state.properties.findIndex((p) => p.id === data.property.id);
    state.properties.splice(index, 1, data.property);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
