import axios from "axios";
import { getApiUrl } from "@/utils";

// initial state
const state = {
  products: [],
  product: {},
  brands: [],
  categories: [],
  attributes: [],
  options: [],
  tags: [],
  archived: [],
};

// getters
const getters = {
  all: (state) => state.products,
  categories: (state) => state.categories,
  attributes: (state) => state.attributes,
  options: (state) => state.options,
  get: (state) => state.product,
};

// actions
const actions = {
  loadProducts({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/craigtoun/products`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadProductsSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadTags({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/craigtoun/products/tags`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadTagsSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadProduct({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/craigtoun/products/${
          data.productId
        }`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadProductSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  updateGalleryOrder({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/craigtoun/products/${
          data.productId
        }/display-order`,
        method: "POST",
        data: {
          displayOrder: data.displayOrder,
        },
      })
        .then((resp) => {
          commit("updateGalleryOrderSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  saveProduct({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/craigtoun/products/${data.productId}`
      : `${getApiUrl(data.appId)}/module/craigtoun/products`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (
        fields[field] === null ||
        typeof fields[field] === "undefined"
      ) {
        formData.append(field, "");
      } else if (typeof fields[field] === "object") {
        if (fields[field] instanceof File) {
          formData.append(field, fields[field]);
        }
        const data = fields[field];
        for (let i = 0; i < data.length; i++) {
          formData.append(field + "[]", data[i]);
        }
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url: url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveProductSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadTagsForProduct({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/craigtoun/products/${
      data.productId
    }/load-tags`;
    return new Promise((resolve, reject) => {
      axios({
        url,
        data: {
          productId: data.productId,
        },
        method: "POST",
      })
        .then((resp) => {
          commit("loadTagsForProductSuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  updateCategoriesDisplayOrder({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(
          data.appId
        )}/module/craigtoun/products-categories/display-order`,
        method: "POST",
        data: {
          displayOrder: data.displayOrder,
        },
      })
        .then((resp) => {
          commit("updateCategoriesDisplayOrderSucess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  saveProductTags({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/craigtoun/products/${
      data.productId
    }/add-tags`;
    return new Promise((resolve, reject) => {
      axios({
        url: url,
        data: {
          ...data.fields,
        },
        method: "POST",
      })
        .then((resp) => {
          const product = resp.data.product;
          commit("saveProductTagsSuccess", {
            product,
          });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  saveOption({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/craigtoun/options`;
    return new Promise((resolve, reject) => {
      axios({
        url: url,
        data: {
          ...data.fields,
        },
        method: "POST",
      })
        .then((resp) => {
          commit("saveOptionSuccess", {
            ...resp.data,
          });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  saveProductRelated({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/craigtoun/products/${
      data.productId
    }/add-related`;
    return new Promise((resolve, reject) => {
      axios({
        url: url,
        data: {
          ...data.fields,
        },
        method: "POST",
      })
        .then((resp) => {
          commit("saveProductRelatedSuccess", {
            ...resp.data,
          });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteProduct({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/craigtoun/products/${
          data.productId
        }`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteProductSuccess", {
            productId: data.productId,
            isMain: data.isMain,
          });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },

  deleteProductImage({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/craigtoun/images/${data.imageId}`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteProductImageSuccess", { imageId: data.imageId });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },

  deleteProductOption({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/craigtoun/products/${
          data.productId
        }/attribute/${data.attributeId}`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteProductOptionSuccess", { productId: data.productId });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },

  loadBrands({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/craigtoun/products/brands`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadBrandsSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadCategories({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/craigtoun/products-categories`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadCategoriesSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  saveCategory({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/craigtoun/products-categories/${
          data.categoryId
        }`
      : `${getApiUrl(data.appId)}/module/craigtoun/products-categories`;

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: data.fields,
        method: "POST",
      })
        .then((resp) => {
          commit("saveCategorySuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteCategory({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/craigtoun/products-categories/${
          data.categoryId
        }`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteCategorySuccess", { categoryId: data.categoryId });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },

  saveOptionProduct({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/craigtoun/products/${
          data.productId
        }/options/${data.optionId}`
      : `${getApiUrl(data.appId)}/module/craigtoun/products/${
          data.productId
        }/options`;

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: data.fields,
        method: "POST",
      })
        .then((resp) => {
          commit("saveOptionProduct", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  saveAttribute({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/craigtoun/attributes/${
          data.attributeId
        }`
      : `${getApiUrl(data.appId)}/module/craigtoun/attributes`;

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: data.fields,
        method: "POST",
      })
        .then((resp) => {
          commit("saveAttributeSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  deleteAddon({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/craigtoun/products/addons/${
          data.addonId
        }`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteAddonSuccess", { addonId: data.addonId });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },
};

// mutations
const mutations = {
  loadProductsSuccess(state, data) {
    state.products = data.products;
    state.archived = data.archived;
  },
  loadBrandsSuccess(state, data) {
    state.brands = data.brands;
  },
  loadProductSuccess(state, data) {
    state.product = data.product;
    state.attributes = data.attributes;
    state.options = data.options;
  },

  saveOptionSuccess(state, data) {
    state.options.push(data.option);
  },

  updateCategoriesDisplayOrderSucess(state, data) {
    state.categories = data.categories;
  },

  uploadImageSuccess() {},
  removeImageSuccess() {},
  saveProductSuccess(state, data) {
    if (data.isEditing) {
      state.product = data.product;
    } else {
      state.products.push(data.product);
    }
  },
  loadTagsForProductSuccess(state, data) {
    state.tags = data.tags;
  },

  saveProductRelatedSuccess(state, data) {
    state.product = data.related;
  },

  saveOptionProduct(state, data) {
    state.product.attributes = data.product.attributes;
  },

  saveAttributeSuccess(state, data) {
    state.attributes.push(data.attribute);
  },

  saveProductTagsSuccess(state, data) {
    const index = state.products.findIndex((p) => p.id === data.product.id);
    state.products.splice(index, 1, data.product);
  },
  deleteProductSuccess(state, data) {
    const index = state.products.findIndex((p) => p.id === data.productId);
    state.archived.push(state.products[index]);
    state.products.splice(index, 1);
  },

  deleteProductOptionSuccess(state, data) {
    const index = state.products.findIndex((p) => p.id === data.productId);
    state.archived.push(state.products[index]);

    state.product.attributes.splice(index, 1);
  },

  deleteProductImageSuccess(state, data) {
    if (data.isMain) {
      state.product.main_image = null;
    } else {
      const index = state.product.gallery.findIndex(
        (c) => c.id === data.imageId
      );
      state.product.gallery.splice(index, 1);
    }
  },

  loadTagsSuccess(state, data) {
    state.tags = data.tags;
  },
  loadCategoriesSuccess(state, data) {
    state.categories = data.categories;
  },
  saveCategorySuccess(state, data) {
    if (data.isEditing) {
      const index = state.categories.findIndex(
        (c) => c.id === data.category.id
      );
      state.categories.splice(index, 1, data.category);
    } else {
      state.categories.push(data.category);
    }
  },
  deleteCategorySuccess(state, data) {
    const index = state.categories.findIndex((c) => c.id === data.categoryId);
    state.categories.splice(index, 1);
  },
  updateGalleryOrderSuccess(state, data) {
    state.product.gallery = data.images;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
