import store from "@/stores/";

export default [
  {
    path: "analysis",
    component: () => import("@/modules/analysis/views/Template"),
    meta: { hasPermission: "view-module-analysis" },
    beforeEnter: (to, from, next) => {
      store.commit("showHideLoader", true);
      Promise.all([
        store.dispatch("analysis/loadAnalysisModule", {
          appId: to.params.id,
        }),
      ]).then(() => {
        store.commit("showHideLoader", false);
        next();
      });
    },
    children: [
      {
        path: "",
        name: "module-analysis",
        component: () => import("@/modules/analysis/views/Dashboard"),
      },
      {
        path: "",
        name: "module-analysis-conversions",
        component: () => import("@/modules/analysis/views/Conversions"),
      },
    ],
  },
];
