import OrderRoutes from "./orders.routes";
import TemplateRoutes from "./templates.routes";

export default [
  {
    path: "graham",
    component: () => import("@/modules/graham/views/Template"),
    children: [...OrderRoutes, ...TemplateRoutes],
  },
];
