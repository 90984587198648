import axios from "axios";
import { getApiUrl } from "@/utils";

// initial state
const state = {
  templates: [],
  archived: [],
  template: {},
};

// getters
const getters = {};

// actions
const actions = {
  loadTemplates({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/graham/templates`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadTemplatesSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteTemplate({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/graham/templates/${
          data.templateId
        }`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteTemplateSuccess", {
            templateId: data.templateId,
          });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },

  loadTemplate({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/graham/templates/${
          data.templateId
        }`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadTemplateSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => reject(err));
    });
  },

  restoreTemplate({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/graham/templates/${
          data.templateId
        }/restore`,
        method: "POST",
      })
        .then((resp) => {
          commit("restoreTemplateSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  populateOrderFromTemplate({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/graham/templates/manuall`;

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: data.fields,
        method: "POST",
      })
        .then((resp) => {
          commit("populateOrderFromTemplateSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  saveTemplate({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/graham/templates/${data.templateId}`
      : `${getApiUrl(data.appId)}/module/graham/templates`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveTemplateSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
};

// mutations
const mutations = {
  loadTemplatesSuccess(state, data) {
    state.templates = data.templates;
    state.archived = data.archived;
  },
  loadTemplateSuccess(state, data) {
    state.template = data.template;
  },
  saveTemplateSuccess(state, data) {
    if (data.isEditing) {
      const index = state.templates.findIndex((p) => p.id === data.template.id);
      state.templates.splice(index, 1, data.template);
      state.template = data.template;
    } else {
      state.templates.push(data.template);
    }
  },

  populateOrderFromTemplateSuccess() {},

  restoreTemplateSuccess(state, data) {
    const index = state.archived.findIndex((c) => c.id === data.template.id);
    state.archived.splice(index, 1);
    state.templates.push(data.template);
  },

  deleteTemplateSuccess(state, data) {
    const index = state.templates.findIndex((c) => c.id === data.templateId);
    state.templates.splice(index, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
