import * as Utils from "@/utils/index.js";

import store from "@/stores/";

export default [
  {
    path: "events",
    component: () => import("@/modules/eow/views/Template"),
    props: (route) => ({
      appColor: Utils.getAppColor(parseInt(route.params.id)),
    }),
    children: [
      {
        path: "",
        name: "module-eow-events",
        component: () => import("@/modules/eow/views/events/Dashboard"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          store
            .dispatch("eow/eventsStore/loadEvents", {
              appId: to.params.id,
            })
            .then(() => {
              store.commit("showHideLoader", false);
              next();
            });
        },
      },
      {
        path: ":eventId",
        name: "module-eow-events-individual",
        component: () => import("@/modules/eow/views/events/Individual"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("eow/eventsStore/loadEvent", {
              appId: to.params.id,
              eventId: to.params.eventId,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
      },
    ],
  },
];
