import axios from "axios";
import { getApiUrl } from "@/utils";

// initial state
const state = {
  events: [],
  archived: [],
  event: {},
};

// getters
const getters = {
  all: (state) => state.events,
  get: (state) => state.event,
};

// actions
const actions = {
  loadEvents({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/eow/events`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadEventsSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadEvent({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/eow/events/${data.eventId}`,
        method: "GET",
      })
        .then((resp) => {
          const event = resp.data.event;
          commit("loadEventSuccess", { event });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  saveEventEmail({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/eow/events/${
      data.eventId
    }/email`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveEventSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  saveEvent({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/eow/events/${data.eventId}`
      : `${getApiUrl(data.appId)}/module/eow/events`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else if (typeof fields[field] === "object") {
        if (fields[field] instanceof File) {
          formData.append(field, fields[field]);
        }
        const data = fields[field];
        for (let i = 0; i < data.length; i++) {
          formData.append(field + "[]", data[i]);
        }
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveEventSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  saveEventDraft({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/eow/events/${
      data.eventId
    }/draft`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveEventsDraftSuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  deleteEvent({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/eow/events/${data.eventId}`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteEventSuccess", {
            eventId: data.eventId,
          });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  loadEventsSuccess(state, data) {
    state.events = data.events;
    state.bookingsAll = data.events;
    state.archived = data.archived;
  },
  loadEventSuccess(state, data) {
    state.event = data.event;
  },
  deleteEventSuccess(state, data) {
    const index = state.events.findIndex((c) => c.id === data.eventId);
    state.events.splice(index, 1);
  },
  saveEventSuccess(state, data) {
    if (data.isEditing) {
      const index = state.events.findIndex((e) => e.id === data.event.id);
      state.events.splice(index, 1, data.event);
      state.event = data.event;
    } else {
      state.events.push(data.event);
    }
  },
  saveEventsDraftSuccess(state, data) {
    const index = state.events.findIndex((p) => p.id === data.event.id);
    state.events.splice(index, 1, data.event);
  },
  saveTicketSuccess(state, data) {
    if (data.isEditing) {
      const index = state.event.tickets.findIndex(
        (t) => t.id === data.ticket.id
      );
      state.event.tickets.splice(index, 1, data.ticket);
    } else {
      state.event.tickets.push(data.ticket);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
