import customers from "./customers.store";
import eventsStore from "./events.store";
import orders from "./orders.store";
import productsStore from "./products.store";

// initial state
const state = {};

// getters
const getters = {};

// actions
const actions = {};

// mutations
const mutations = {};

// modules
const modules = {
  productsStore,
  eventsStore,
  orders,
  customers,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules,
};
