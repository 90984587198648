import store from "@/stores/";

export default [
  {
    path: "customers",
    component: () => import("@/modules/customers/views/Template"),
    meta: { hasPermission: "view-module-customers" },
    beforeEnter: (to, from, next) => {
      store.commit("showHideLoader", true);
      store
        .dispatch("customers/loadModule", {
          appId: to.params.id,
          module: "customers",
        })
        .then(() => {
          store.commit("showHideLoader", false);
          next();
        });
    },
    children: [
      {
        path: "",
        name: "module-customers",
        component: () => import("@/modules/customers/views/Dashboard"),
      },
      {
        path: "archived",
        name: "module-customers-archived",
        component: () => import("@/modules/customers/views/Archived"),
      },
      {
        path: ":customerId",
        component: () => import("@/modules/customers/views/Profile"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          store.dispatch("customers/loadProfile", {
            appId: to.params.id,
            customerId: to.params.customerId,
            module: "customer_contacts",
          }),
            store
              .dispatch("workload/loadModule", {
                appId: to.params.id,
                module: "workload",
                customerId: to.params.customerId,
              })
              .then(() => {
                store.commit("showHideLoader", false);
                next();
              });
        },
        children: [
          {
            path: "",
            name: "customer-profile-basic",
            component: () =>
              import("@/modules/customers/views/profile/BasicDetails"),
          },
          {
            path: "contacts",
            name: "customer-profile-contacts",
            component: () =>
              import("@/modules/customers/views/profile/Contacts"),
          },
          {
            path: "domains",
            name: "customer-profile-domains",
            component: () =>
              import("@/modules/customers/views/profile/Domains"),
          },
          {
            path: "finance",
            name: "customer-profile-finance",
            component: () =>
              import("@/modules/customers/views/profile/Finance"),
          },
          {
            path: "integrations",
            name: "customer-profile-integrations",
            component: () =>
              import("@/modules/customers/views/profile/Integrations"),
          },
          {
            path: "workload",
            name: "customer-profile-workload",
            beforeEnter: (to, from, next) => {
              store.commit("showHideLoader", true);
              Promise.all([
                store.dispatch("team/loadTeamMembers", {
                  appId: to.params.id,
                }),
              ]).then(() => {
                store.commit("showHideLoader", false);
                next();
              });
            },
            component: () =>
              import("@/modules/customers/views/profile/Workload"),
          },
        ],
      },
    ],
  },
];
