import CustomersRoutes from "./customers.routes";
import MembershipsRoutes from "./memberships.routes";

export default [
  {
    path: "csear",
    component: () => import("@/modules/csear/views/Template"),
    children: [...CustomersRoutes, ...MembershipsRoutes],
  },
];
