import axios from "axios";
import { getApiUrl } from "@/utils";

const state = {
  moveouts: [],
  archived: [],
  moveout: {},
};

// getters
const getters = {
  all: (state) => state.moveouts,
  archived: (state) => state.archived,
  get: (state) => state.moveout,
};

// actions
const actions = {
  loadMoveOuts({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/sbpm/moveouts`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadMoveOutsSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  loadMoveOut({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/sbpm/moveouts/${data.moveoutId}`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadMoveOutSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  saveMoveout({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/sbpm/moveouts/${data.moveoutId}`
      : `${getApiUrl(data.appId)}/module/sbpm/moveouts`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveMoveoutSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
  deleteMoveout({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/sbpm/moveouts/${data.moveoutId}`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteMoveoutSuccess", {
            moveoutId: data.moveoutId,
          });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  saveDetail({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/sbpm/checklists/detail/${
      data.detailId
    }`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveDetailSuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  saveNote({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/sbpm/moveouts/${
      data.moveoutId
    }/note`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveNoteSuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  restore({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/sbpm/moveouts/${
          data.moveoutId
        }/restore`,
        method: "POST",
      })
        .then((resp) => {
          commit("restoreMoveoutSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  loadMoveOutsSuccess(state, data) {
    state.moveouts = data.moveouts;
    state.archived = data.archived;
  },

  loadMoveOutSuccess(state, data) {
    state.moveout = data.moveout;
  },

  saveMoveoutSuccess(state, data) {
    if (data.isEditing) {
      const index = state.moveouts.findIndex((p) => p.id === data.moveout.id);
      state.moveouts.splice(index, 1, data.moveout);

      state.moveout = data.moveout;
    } else {
      state.moveouts.push(data.moveout);
    }
  },

  deleteMoveoutSuccess(state, data) {
    const index = state.moveouts.findIndex((c) => c.id === data.moveoutId);
    state.moveouts.splice(index, 1);
  },

  saveDetailSuccess(state, data) {
    const index = state.moveout.checklists.findIndex(
      (p) => p.pivot.id === data.detail.id
    );

    state.moveout.checklists[index].pivot = data.detail;

    if (data.audit != "") {
      state.moveout.audits.push(data.audit);
    }
  },

  saveNoteSuccess(state, data) {
    state.moveout.notes.push(data.note);
  },

  saveMoveoutVisibleSuccess(state, data) {
    const index = state.moveouts.findIndex((p) => p.id === data.moveout.id);
    state.moveouts.splice(index, 1, data.moveout);
  },

  restoreMoveoutSuccess(state, data) {
    const index = state.archived.findIndex((c) => c.id === data.moveoutId);
    state.archived.splice(index, 1);
    state.moveouts.push(data.moveout);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
