import axios from "axios";
import { getApiUrl } from "@/utils";

// initial state
const state = {
  orders: [],
  archived: [],
  order: {},
};

// getters
const getters = {};

// actions
const actions = {
  loadOrders({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/graham/orders`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadOrdersSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteOrder({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/graham/orders/${data.orderId}`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteOrderSuccess", {
            orderId: data.orderId,
          });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },

  loadOrder({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/graham/orders/${data.orderId}`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadOrderSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => reject(err));
    });
  },

  restoreOrder({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/graham/orders/${
          data.orderId
        }/restore`,
        method: "POST",
      })
        .then((resp) => {
          commit("restoreOrderSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  sendToCustomer({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/graham/orders/${
          data.orderId
        }/send-to-customer`,
        method: "POST",
      })
        .then((resp) => {
          commit("sendToCustomerSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  saveOrderAddress({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/graham/orders/${
      data.orderId
    }/address`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveOrderSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  saveOrder({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/graham/orders/${data.orderId}`
      : `${getApiUrl(data.appId)}/module/graham/orders`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveOrderSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
};

// mutations
const mutations = {
  loadOrdersSuccess(state, data) {
    state.orders = data.orders;
    state.archived = data.archived;
  },
  loadOrderSuccess(state, data) {
    state.order = data.order;
  },
  saveOrderSuccess(state, data) {
    if (data.isEditing) {
      if (data.order.content) {
        delete data.order.content["Checkbox"];
      }

      state.order = data.order;
    } else {
      state.orders.unshift(data.order);
    }
  },

  populateOrderFromTemplateSuccess() {},

  restoreOrderSuccess(state, data) {
    const index = state.archived.findIndex((c) => c.id === data.order.id);
    state.archived.splice(index, 1);
    state.orders.push(data.order);
  },

  deleteOrderSuccess(state, data) {
    const index = state.orders.findIndex((c) => c.id === data.orderId);
    state.orders.splice(index, 1);
  },

  sendToCustomerSuccess(state, data) {
    state.order = data.order;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
