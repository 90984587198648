import store from "@/stores/";

export default [
  {
    path: "templates",
    component: () => import("@/modules/graham/views/Template"),
    children: [
      {
        path: "",
        name: "module-graham-templates",
        component: () => import("@/modules/graham/views/templates/Dashboard"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("graham/templates/loadTemplates", {
              appId: to.params.id,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
      },
      {
        path: "archived",
        name: "module-graham-templates-archived",
        component: () => import("@/modules/graham/views/templates/Archived"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("graham/templates/loadTemplates", {
              appId: to.params.id,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
      },
      {
        path: ":templateId",
        name: "module-graham-templates-individual",
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("graham/templates/loadTemplate", {
              appId: to.params.id,
              templateId: to.params.templateId,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
        component: () => import("@/modules/graham/views/templates/Individual"),
      },
    ],
  },
];
