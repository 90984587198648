import store from "@/stores/";

export default [
  {
    path: "orders",
    component: () => import("@/modules/craigtoun/views/Template"),
    beforeEnter: (to, from, next) => {
      store.commit("showHideLoader", true);
      Promise.all([
        store.dispatch("craigtoun/ordersStore/loadOrders", {
          appId: to.params.id,
        }),
      ]).then(() => {
        store.commit("showHideLoader", false);
        next();
      });
    },
    children: [
      {
        path: "",
        name: "module-craigtoun-orders",
        component: () => import("@/modules/craigtoun/views/orders/Dashboard"),
      },
      {
        path: ":orderId",
        name: "module-craigtoun-orders-individual",
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("craigtoun/ordersStore/loadOrder", {
              appId: to.params.id,
              orderId: to.params.orderId,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
        component: () => import("@/modules/craigtoun/views/orders/Individual"),
      },
    ],
  },
];
