import * as Utils from "@/utils/index.js";

import CustomerRoutes from "./customers.routes";
import EventRoutes from "./events.routes";
import OrderRoutes from "./orders.routes";
import ProductRoutes from "./products.routes";

export default [
  {
    path: "eow",
    component: () => import("@/modules/eow/views/Template"),
    props: (route) => ({
      appColor: Utils.getAppColor(parseInt(route.params.id)),
    }),
    children: [
      ...ProductRoutes,
      ...OrderRoutes,
      ...CustomerRoutes,
      ...EventRoutes,
    ],
  },
];
