import axios from "axios";
import { getApiUrl } from "@/utils";

// initial state
const state = {
  memberships: [],
  types: [],
  archived: [],
  membership: {},
};

// getters
const getters = {
  all: (state) => state.memberships,
  get: (state) => state.membership,
  types: (state) => state.types,
};

// actions
const actions = {
  loadMemberships({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/csear/memberships`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadMembershipsSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadMembership({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/csear/memberships/${
          data.membershipId
        }`,
        method: "GET",
      })
        .then((resp) => {
          const membership = resp.data.membership;
          commit("loadMembershipSuccess", { membership });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadTypes({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/csear/memberships/types`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadTypesSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  saveMembership({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/csear/memberships/${data.membershipId}`
      : `${getApiUrl(data.appId)}/module/csear/memberships`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (
        fields[field] === null ||
        typeof fields[field] === "undefined"
      ) {
        formData.append(field, "");
      } else if (typeof fields[field] === "object") {
        if (fields[field] instanceof File) {
          formData.append(field, fields[field]);
        }
        const data = fields[field];
        for (let i = 0; i < data.length; i++) {
          formData.append(field + "[]", data[i]);
        }
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveMembershipSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
};

// mutations
const mutations = {
  loadMembershipsSuccess(state, data) {
    state.memberships = data.memberships;
    state.archived = data.archived;
  },
  loadMembershipSuccess(state, data) {
    state.membership = data.membership;
  },
  loadTypesSuccess(state, data) {
    state.types = data.types;
  },
  saveMembershipSuccess(state, data) {
    if (data.isEditing) {
      const index = state.memberships.findIndex(
        (p) => p.id === data.membership.id
      );
      state.memberships.splice(index, 1, data.membership);
      state.membership = data.membership;
    } else {
      state.memberships.push(data.membership);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
