import brochureRoutes from "./brochures.routes";
import offerRoutes from "./offers.routes";
import productRoutes from "./products.routes";
import settingsRoutes from "./settings.routes";
import testimonialRoutes from "./testimonials.routes";

export default [
  {
    path: "petroshops",
    component: () => import("@/modules/petroshops/views/Template"),
    children: [
      ...productRoutes,
      ...offerRoutes,
      ...brochureRoutes,
      ...testimonialRoutes,
      ...settingsRoutes,
    ],
  },
];
